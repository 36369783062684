import React, { useState,useEffect } from 'react'
import {Link, navigate} from 'gatsby'
import {useSpring,animated,config} from 'react-spring'

import "@fontsource/roboto"

import {Wrap,WrapItem} from '@chakra-ui/react'
import "@fontsource/roboto-slab"

import {
    Box,Text,Flex,Button,
    SimpleGrid
  } from "@chakra-ui/react"





 const Links = () => {

     return(
         <>

         <Flex top="0"   width="100%"  justifyContent="space-between">
         <Box flex="10" role="button" onClick={()=>navigate("/")}>
      <Text variant="maintitle">hasen oder bein</Text>
        <div style={{fontFamily:'Roboto',fontWeight:100,marginBottom:50,fontSize:"1.0rem"}}>gestaltet kommunikation.</div>
          </Box>

         </Flex>
         </>
     )
 }

const Layout =({children})=> {
    const [flag,setFlag]=useState(false)




    useEffect(()=>{
         if(typeof window && window.localStorage.getItem("hasenoderbein")){
             setFlag(true)
         }
     },[flag])
    function machflag(){
        setFlag(!flag)
         window && window.localStorage.setItem("hasenoderbein",true)
       }

    const Daten =() => {
       return(
           <Flex justifyContent="flex-start" alignItems="flex-end" ml="1" pl="4" maxWidth="920" width={["90%","90%"]}  fontSize="2xl" position="fixed" zIndex="50" bottom="0" background="gray.600" color="white">
           <SimpleGrid columns={[1,1,2]} spacing={4}>


          <Box mt="4"> <Link to="/datenschutz"><Text fontFamily="Roboto Slab" size="18" color="white"> &rarr; Lesen Sie unsere Datenschutzbestimmungen</Text> </Link></Box>
           <Button bg="transparent" border="1px solid white" py="2" m="2" color="white" onClick={()=>machflag()}>Ich bin einverstanden </Button>
           </SimpleGrid >
           </Flex>
       )
      }

    const xstyles = useSpring({
        loop: false,
        to:

          { paddingTop:0, opacity: 1, fontSize: '2rem',color: 'rgb(14,26,19)',config:config.gentle },

        from: { paddingTop: -350,opacity: .3, fontSize: '1rem',color: 'gray' },
      })

    return(
        <animated.div style={xstyles}>


          <Box  mt="30" margin="auto" maxWidth="980" p={["2","4","6"]}  overflow="y-scroll" >
           <header>
               <nav>
                   <Box>
                       <Links />
                   </Box>
                </nav>
           </header>

           <main>
           {!flag && <Daten />}

             {children}


           </main>

          <footer style={
               {
                   position:'relative',
                   bottom:0,
                   marginTop:30

               }
           }>
            <Wrap>
                  <WrapItem fontSize="1rem"><Link to="/datenschutz"> &rarr; Datenschutz</Link></WrapItem>
                  <WrapItem fontSize="1rem"><Link to="/impressum"> &rarr; Impressum</Link></WrapItem>
                  <WrapItem fontSize="1rem"><Link to="/kontakt"> &rarr; Kontakt</Link></WrapItem>
              </Wrap>
           </footer>
          </Box>

          </animated.div>
    )
}
export default Layout
