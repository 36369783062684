// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktivitaet-js": () => import("./../../../src/pages/aktivitaet.js" /* webpackChunkName: "component---src-pages-aktivitaet-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-finetrader-js": () => import("./../../../src/pages/finetrader.js" /* webpackChunkName: "component---src-pages-finetrader-js" */),
  "component---src-pages-heim-js": () => import("./../../../src/pages/heim.js" /* webpackChunkName: "component---src-pages-heim-js" */),
  "component---src-pages-illuinfo-js": () => import("./../../../src/pages/illuinfo.js" /* webpackChunkName: "component---src-pages-illuinfo-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jahresbuecher-js": () => import("./../../../src/pages/jahresbuecher.js" /* webpackChunkName: "component---src-pages-jahresbuecher-js" */),
  "component---src-pages-kom-js": () => import("./../../../src/pages/kom.js" /* webpackChunkName: "component---src-pages-kom-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-markt-1-js": () => import("./../../../src/pages/markt1.js" /* webpackChunkName: "component---src-pages-markt-1-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-mood-js": () => import("./../../../src/pages/mood.js" /* webpackChunkName: "component---src-pages-mood-js" */),
  "component---src-pages-periodika-js": () => import("./../../../src/pages/periodika.js" /* webpackChunkName: "component---src-pages-periodika-js" */),
  "component---src-pages-studiofoto-js": () => import("./../../../src/pages/studiofoto.js" /* webpackChunkName: "component---src-pages-studiofoto-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-zblatt-js": () => import("./../../../src/pages/zblatt.js" /* webpackChunkName: "component---src-pages-zblatt-js" */)
}

